
import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";

export default defineComponent({
  name: "add-association",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      entity: {
        division: "",
        district: "",
        sub_district: "",
        entity_description: "",
      },
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      loading: false,
      lists: [] as any,
      upazila_name_eng: "" as any,
      deadline: "" as any,
      grid_id: [] as any,
      employeeList: "" as any,
      name: "" as any,
      gridList: "" as any,
      roleByemployeeList: "" as any,
      rs_id: "" as any,
      rs_role_id: 3,
      upazila_id: "" as any,
      assigned_to_rs_id: "" as any,
      assigned_by_gis_id: "" as any,
      current_status: "1",
      task_type: "1",
    };
  },
  async created() {
    await this.geoUpazila();
    await this.getDivision();
    // await this.getGrid();
    await this.getDistrictForEmp();
    await this.getRoleEmployee();
    
    this.assigned_by_gis_id = VueCookieNext.getCookie('_seip_employee_id');
  },
  methods: {
    async geoUpazila() {
      await ApiService.get("configurations/geo_upazila/list")
        .then((response) => {
          this.lists = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getRoleEmployee() {
      await ApiService.get("configurations/employee/roleList?role_id="+this.rs_role_id)
        .then((response) => {
          this.roleByemployeeList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getGrid() {
      console.log(this.upazila_id);
      await ApiService.get("configurations/geo_grid/list?upazila_id="+this.upazila_id)
        .then((response) => {
          this.gridList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async formSubmit() { 
      let formData = new FormData();

      formData.set('upazila_id', this.upazila_id);
      formData.set('grid_id', this.grid_id);
      formData.set('assigned_to_rs_id', this.assigned_to_rs_id);
      formData.set('assigned_by_gis_id', this.assigned_by_gis_id);
      formData.set('deadline', this.deadline);
      formData.set('task_type', this.task_type);
      formData.set('current_status', this.current_status);
      formData.set('created_by', this.assigned_by_gis_id);
      formData.set('updated_by', this.assigned_by_gis_id);
      
      this.loading = true;
      await ApiService.post('configurations/task/save',formData)
      .then((response) => {
          this.loading = false;
          if(response.data.status=='success')
          {
            Swal.fire({
                text:  'Save Successfully.',
                icon: "success",
                buttonsStyling: false,
                heightAuto: false,
                customClass: {
                confirmButton: "btn fw-semobold btn-success",
              },
            }).then(() => {
              this.$router.push('/dash');
            });
          }
          else
          {
            Swal.fire({
                text:  'Something Went Wrong!',
                icon: "warning",
                buttonsStyling: false,
                heightAuto: false,
                customClass: {
                  confirmButton: "btn fw-semobold btn-danger",
              },
            });
          }
          
      })
      .catch(({ response }) => {
        console.log(response);
      });
    },
    async getDivision() {
      await ApiService.get("geo/divisions")
        .then((response) => {
          this.divisions = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrict() {
      await ApiService.get("geo/districts?division=" + this.entity.division)
        .then((response) => {
          this.districts = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrictForEmp() {
      await ApiService.get("geo/districts")
        .then((response) => {
          this.empDistricts = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getSubDistrict() {
      await ApiService.get(
        "geo/upazilas?division=" +
        this.entity.division +
        "&district=" +
        this.entity.district
      )
        .then((response) => {
          this.subDistricts = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
 
    return {
    };
  },
});
